import {
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import React, { useCallback, useState } from "react";
import {
  digits,
  lower,
  randomPassword,
  symbols,
  upper,
} from "secure-random-password";
import ApiService from "../services/ApiService";

export function FormHeader({ updateTools }) {
  return (
    <Header
      variant="h1"
      description={
        <>
          <div>
            When you submit your company to the company cloud you will cause it
            to grow.
            <ol>
              <li>Fill in your details</li>
              <li>You will receive a confirmation code in your mailbox</li>
              <li>Fill in the confirmation code</li>
              <li>
                Watch your company grow ☁️! If your company is not on the word
                cloud yet this might take a few minutes. If it takes too long
                feel free to ask one of the booth admins for help.
              </li>
            </ol>
            Good luck on becoming the No. 1 company!
          </div>
        </>
      }
    >
      Represent your company!
    </Header>
  );
}

function BaseFormContent({
  content,
  onCancelClick,
  loading,
  canSubmit,
  errorText = null,
  submit = () => {},
  actions = [],
}) {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      <Form
        actions={
          <>
            <SpaceBetween direction="horizontal" size="xs">
              {loading && (
                <Button loading disabled={loading || !canSubmit} variant="link">
                  Submitting
                </Button>
              )}
              <Button
                disabled={loading}
                formAction="none"
                variant="link"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button disabled={loading || !canSubmit} variant="primary">
                Submit
              </Button>
              {actions}
            </SpaceBetween>
          </>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content}
      </Form>
    </form>
  );
}

const apiService = new ApiService();
export function FormContent({ setNotification, dismissNotification }) {
  const [company, setCompany] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [consent, setConsent] = useState(false);
  const [code, setCode] = useState("");

  const [step, setStep] = useState(0);

  const [isLoading, setLoading] = useState(false);
  const [waitForResend, setWaitForResend] = useState(true);
  const [waitSeconds, setWaitSeconds] = useState(60);

  const getErrorText = (errorMessage) => {
    return undefined;
  };

  const wait = useCallback(async () => {
    setWaitForResend(true);
    for (let wait = 60; wait > 0; wait--) {
      setWaitSeconds(wait);
      await apiService.sleep(1000);
    }
    setWaitForResend(false);
  }, [setWaitForResend, setWaitSeconds]);

  const submit = useCallback(async () => {
    window.scrollTo(0, 0);

    setLoading(true);
    setNotification({
      type: "success",
      loading: true,
      content: "Sending your request.",
      id: "send_request",
    });
    try {
      await Auth.signUp({
        username: email,
        password: randomPassword({
          length: 15,
          characters: [lower, upper, digits, symbols],
        }),
        attributes: {
          email,
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          "custom:country": country,
          "custom:company": company,
          "custom:consent": "" + consent,
        },
      });
    } catch (error) {
      // Handle
      setLoading(false);
      setNotification({
        type: "error",
        loading: false,
        dismissible: true,
        onDismiss: () => dismissNotification(),
        header: "Oops!",
        content:
          "Something went wrong when submitting your entry. Please try again later.",
        id: "send_request",
      });
      return;
    }
    setLoading(false);
    dismissNotification();
    wait();
    setStep(1);
  }, [company, firstName, lastName, email, country, consent]);

  const submitCode = useCallback(async () => {
    window.scrollTo(0, 0);

    setLoading(true);
    try {
      await Auth.confirmSignUp(email, code);
    } catch (error) {
      // Handle
      setLoading(false);
      setNotification({
        type: "error",
        loading: false,
        dismissible: true,
        onDismiss: () => dismissNotification(),
        header: "Oops!",
        content:
          "Something went wrong when submitting your code. Please try again later.",
        id: "send_code",
      });
      return;
    }
    setLoading(false);
    dismissNotification();
    setStep(2);
  }, [email, code]);

  const resubmit = useCallback(async () => {
    setLoading(true);
    try {
      console.log(email);
      await Auth.resendSignUp(email);
    } catch (error) {
      // Handle
      setLoading(false);
      setNotification({
        type: "error",
        loading: false,
        dismissible: true,
        onDismiss: () => dismissNotification(),
        header: "Oops!",
        content:
          "Something went wrong when submitting your code. Please try again later.",
        id: "send_code",
      });
      return;
    }
    wait();
    setLoading(false);
  }, [email]);

  switch (step) {
    default:
    case 0:
      return (
        <BaseFormContent
          submit={submit}
          canSubmit={
            company.length > 0 &&
            firstName.length > 0 &&
            lastName.length > 0 &&
            email.length > 0 &&
            country.length > 0 &&
            consent
          }
          loading={isLoading}
          content={
            <SpaceBetween size="l">
              <Container
                id="company"
                className="custom-screenshot-hide"
                header={<Header variant="h2">Represent!</Header>}
              >
                <SpaceBetween size="l">
                  <FormField
                    label="Company"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="Example Company"
                      ariaRequired={true}
                      value={company}
                      onChange={(event) => setCompany(event.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
              <Container
                id="origin-panel"
                className="custom-screenshot-hide"
                header={<Header variant="h2">About you</Header>}
              >
                <SpaceBetween size="l">
                  <FormField
                    label="First Name"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="John"
                      ariaRequired={true}
                      value={firstName}
                      onChange={(event) => setFirstName(event.detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Last Name"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="Doe"
                      ariaRequired={true}
                      value={lastName}
                      onChange={(event) => setLastName(event.detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Email"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="john.doe@example.com"
                      ariaRequired={true}
                      value={email}
                      onChange={(event) => setEmail(event.detail.value)}
                    />
                  </FormField>
                  <FormField
                    label="Country"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="The Netherlands"
                      ariaRequired={true}
                      value={country}
                      onChange={(event) => setCountry(event.detail.value)}
                    />
                  </FormField>
                  <FormField
                    // label="Country"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Checkbox
                      onChange={({ detail }) => setConsent(detail.checked)}
                      checked={consent}
                    >
                      I agree to receive personalised marketing emails.
                    </Checkbox>
                  </FormField>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          }
        />
      );
    case 1:
      return (
        <BaseFormContent
          submit={submitCode}
          loading={isLoading}
          canSubmit={code.length > 0}
          actions={[
            <Button
              key="resubmit"
              formAction="none"
              disabled={isLoading || waitForResend}
              onClick={(ev) => resubmit()}
            >
              Resend code {waitForResend && <>({waitSeconds}s)</>}
            </Button>,
          ]}
          content={
            <SpaceBetween size="l">
              <Container
                id="code"
                className="custom-screenshot-hide"
                header={<Header variant="h2">Code</Header>}
              >
                <SpaceBetween size="l">
                  <FormField
                    label="Code"
                    // description="The directory in your Amazon S3 bucket or your custom origin."
                    errorText={getErrorText(
                      "You must specify a path to content."
                    )}
                    i18nStrings={{ errorIconAriaLabel: "Error" }}
                  >
                    <Input
                      placeholder="12345678"
                      ariaRequired={true}
                      value={code}
                      onChange={(event) => setCode(event.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          }
        />
      );
    case 2:
      return (
        <Container
          id="thankyou"
          className="custom-screenshot-hide"
          header={<Header variant="h2">Thank you!</Header>}
        >
          <p>
            Your company registration has been succesfully completed. You will
            see your company growing on the dashboard shortly.
          </p>
          <p>You can now safely navigate away.</p>
        </Container>
      );
  }
}
