import superagent from "superagent";

const api_url =
  "https://yc4oxhkfjd.execute-api.eu-central-1.amazonaws.com/Prod";

class ApiService {
  async sleep(duration = 1000) {
    return await new Promise((resolve) =>
      setTimeout(() => resolve(), duration)
    );
  }

  async addToCloud(data) {
    const result = await superagent
      .post(`${api_url}/companies`)
      .set("content-type", "application/json")
      .set("accept", "application/json")
      .send(data);

    return result.body;
  }
}

export default ApiService;
