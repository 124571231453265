import {
  AppLayout,
  ContentLayout,
  Flashbar,
} from "@cloudscape-design/components";
import { Amplify, Auth } from "aws-amplify";
import React, { useState } from "react";
import "./App.css";
import { FormContent, FormHeader } from "./components/form-content";
import { appLayoutLabels } from "./labels";
import "./styles/form.scss";

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_yhjixZT0E",
    userPoolWebClientId: "1h73gtlemm5fg4nigakjnh4f2m",
  },
});
Auth.configure();

function App() {
  const [notifications, setNotifications] = useState([]);

  return (
    <AppLayout
      contentType="form"
      content={
        <ContentLayout header={<FormHeader />}>
          <FormContent
            setNotification={(notification) => setNotifications([notification])}
            dismissNotification={() => setNotifications([])}
          />
        </ContentLayout>
      }
      headerSelector="#header"
      navigationHide
      toolsHide
      ariaLabels={appLayoutLabels}
      notifications={<Flashbar items={notifications} />}
    />
  );
}

export default App;
